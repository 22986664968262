import * as React from "react"
import { graphql } from "gatsby"
import Button from "../components/common/button"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import CommentBox from "../components/coment-box"

import makromusicLogo from "../images/makromusic-logo.png"
import forArtistsLogo from "../images/for-artists-logo.png"
import makrosocialLogo from "../images/makrosocial-logo.png"
import makrosocialMiniLogo from "../images/makrosocial-mini-logo.svg"
import makromusicMiniLogo from "../images/makromusic-mini-logo.svg"
import forArtistsMiniLogo from "../images/for-artists-mini-logo.svg"
// import teamImg from "../images/team.png"
// import arrowIcon from "../images/icons/arrow.svg"
import PressBox from "../components/press-box"
import { NEWS } from "./../constants/items"
import AnimateProducts from "../components/animate-products"
import { useTranslation } from "gatsby-plugin-react-i18next"
import ROUTES from "../constants/routes"

const IndexPage = () => {
  const { t } = useTranslation()

  return (
    <Layout>
      <Seo title={t("navbarHomePage")} />
      <div>
        <div className="container h-screen hero-container flex items-center">
          <div className="w-1/2 ph-full hero-space">
            <h1 className="hero-title m-0">{t("heroTitle")}</h1>
            <p className="hero-desc">
              {t('heroDesc')}
            </p>
            <div className="inline-block">
              <Button link={"/products"}  type="primary-big">{t("heroProductsBtn")}</Button>
            </div>
          </div>
          <div className="w-1/2 flex justify-center ph-hidden">
            <div className="hero-brands">
              <div className="mini-logo-holder bg-dark">
                <img width={50} height={50} src={makrosocialMiniLogo} alt="makrosocial mini logo" />
              </div>
              <div className="mini-logo-holder bg-dark">
                <img width={50} height={50} src={makromusicMiniLogo} alt="makromusic mini logo" />
              </div>
              <div className="mini-logo-holder bg-makromusic">
                <img width={50} height={50} src={forArtistsMiniLogo} alt="makromusic mini logo" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center brands">
        
        <a href={ROUTES.MAKROMUSIC} target="_blank" rel="noreferrer">
          <img src={makromusicLogo} className="brands-logo" alt="makromusic logo" />
        </a>
        <a href={ROUTES.MAKROMUSIC_FOR_ARTISTS} target="_blank" rel="noreferrer">
          <img
            src={forArtistsLogo}
            className="brands-logo"
            alt="makromusic for artists logo"
          />
        </a>
        <a href={ROUTES.MAKROSOCIAL} target="_blank" rel="noreferrer">
          <img src={makrosocialLogo} className="brands-logo" alt="makrosocial logo" />
        </a>
      </div>
      <div className="container min-h-screen feedback">
        <div className="feedback-content">
          <div>
            <h2 className="title">{t("feedbackTitle")}</h2>
            <p>{t("feedbackDesc")}</p>
          </div>
          <div className="flex comments-container">
            <div>
              <div className="box-1">
                <CommentBox
                  personName={t("feedback0Name")}
                  personTitle={t("feedback0Title")}
                  logo={makromusicLogo}
                  comment={t("feedback0Txt")}
                />
              </div>
            </div>
            <div>
              <div className="box-2">
                <CommentBox
                  personName={t("feedback1Name")}
                  personTitle={t("feedback1Title")}
                  logo={makrosocialLogo}
                  comment={t("feedback1Txt")}
                />
              </div>
              <div className="box-3">
                <CommentBox
                  extraLogo={"for Artists"}
                  personName={t("feedback2Name")}
                  personTitle={t("feedback2Title")}
                  logo={makromusicLogo}
                  comment={t("feedback2Txt")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <AnimateProducts />
      {/* <div className="container team">
        <h2 className="title">Ekibimiz hakkında</h2>
        <img className="team-poster" src={teamImg} alt="makromusic team" />
        <p className="team-desc">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Condimentum
          diam orci pretium a pharetra, feugiat cursus. Dictumst risus, sem
          egestas odio cras adipiscing vulputate. Nisi, risus in suscipit non.
          Non commodo volutpat, pharetra, vel.
        </p>
        <Link to={"/"} className="view-link">
          İncele <img src={arrowIcon} alt="arrow" />
        </Link>
      </div> */}
      <div className="container press">
        <div className="press-boxes">
          {NEWS.map((item, i) => (
            <div className={"press-box-ct press-box-" + (i + 1)}>
              <PressBox
                height={item.height}
                link={item.link}
                title={t(item.title)}
                desc={item.desc}
                img={item.img}
              />
            </div>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
