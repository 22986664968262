import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next';

import arrowIcon from '../images/icons/arrow.svg';
import makromusicLogo from '../images/makromusic-logo.png';
import makrosocialLogo from '../images/makrosocial-white-logo.png';
import forArtistsLogo from '../images/for-artists-logo.png';
import makromusicImg from '../images/products/mm.png';
import makromusicMobileImg from '../images/products/mm-ph-2.png';
import makrosocialMobileImg from '../images/products/makrosocial-ph-2.png';
import makrosocialImg from '../images/products/makrosocial.png';
import forArtistsImg from '../images/products/mm-for-artists.png';

import ROUTES from '../constants/routes';

// var lastScrollTop = 0;

const AnimateProducts = () => {

  const {t} = useTranslation()

  const productScreensRef = useRef(null);
  const productContentRef = useRef(null);
  const product0ContainRef = useRef(null);
  const product1ContainRef = useRef(null);
  const product2ContainRef = useRef(null);
  const productImgRef = useRef(null);
  const productBgRef = useRef(null);
  

  // const onScroll = (e) => {

  //   let scrollUp = false;
  //   const windowHeight = window.innerHeight;
  //   const scrollPos = document.getElementsByTagName('html')[0].scrollTop;
  //   const psTop = productScreensRef.current.offsetTop;

  //   var st = window.pageYOffset || document.documentElement.scrollTop;
  //   if (st > lastScrollTop){
  //     scrollUp = false;
  //   } else {
  //     scrollUp = true;
  //   }
  //   lastScrollTop = st <= 0 ? 0 : st;


  //   if (scrollPos > psTop) {
  //     // ghostProductContentRef.current.style.width = productContentRef.current.offsetWidth +'px';
  //     // productContentRef.current.style.width = productContentRef.current.offsetWidth +'px'
  //     productContentRef.current.style.position = 'fixed';
  //     productContentRef.current.style.top = `calc(50% - ${productContentRef.current.offsetHeight/2}px)`;

  //     console.log(scrollPos, psTop + windowHeight, scrollUp);

  //     if (scrollPos > psTop + windowHeight/2) {
  //       productBgRef.current.classList.add('product-bg-1');
  //     }
  //     if (scrollPos < psTop + windowHeight/2) {
  //       productBgRef.current.classList.remove('product-bg-1');
  //     }


  //     if (scrollPos > psTop + 70 && scrollPos < psTop + 100 && !scrollUp) {  
              
  //       window.scrollTo({
  //         top: psTop + windowHeight,
  //         behavior: 'smooth'
  //       });
  //     }

  //     if (scrollPos > psTop + windowHeight - 160 && scrollPos < psTop + windowHeight - 130 && scrollUp) {
  //       window.scrollTo({
  //         top: psTop,
  //         behavior: 'smooth'
  //       });
  //     }
  //   } else {
  //     productContentRef.current.style.position = 'relative';
  //     productContentRef.current.style.top = ''
  //   }
    
  // }

  const onScroll2 = (e) => {
    const scrollPos = document.getElementsByTagName('html')[0].scrollTop;
    const psTop = productScreensRef.current.offsetTop;
    const psHeight = productScreensRef.current.offsetHeight;

    if (scrollPos > psTop && scrollPos < psTop + psHeight - window.innerHeight) {

      if (scrollPos > psTop && scrollPos < psTop + (window.innerHeight/2) ) {
        product0ContainRef.current.style.opacity = 1;
        product1ContainRef.current.style.opacity = 0;
        product2ContainRef.current.style.opacity = 0;
        productBgRef.current.className = "product-bg-0";
      }
      if (scrollPos > psTop + (window.innerHeight/2) && scrollPos < psTop + window.innerHeight +200 ) {
        product0ContainRef.current.style.opacity = 0;
        product1ContainRef.current.style.opacity = 1;
        product2ContainRef.current.style.opacity = 0;
        productBgRef.current.className = "product-bg-1";
      }
      if (scrollPos > psTop + window.innerHeight +200 ) {
        product0ContainRef.current.style.opacity = 0;
        product1ContainRef.current.style.opacity = 0;
        product2ContainRef.current.style.opacity = 1;
        productBgRef.current.className = "product-bg-2";
      }

      product0ContainRef.current.style.position = 'fixed'
      product1ContainRef.current.style.position = 'fixed'
      product2ContainRef.current.style.position = 'fixed'
    } else {
      product0ContainRef.current.style.position = 'unset'
      product1ContainRef.current.style.position = 'unset'
      product2ContainRef.current.style.position = 'unset'
    }

  }

  useEffect(() => {


    // productContentRef.current.style.width = productContentRef.current.offsetWidth +'px'

    document.addEventListener('scroll', onScroll2);
    
    return () => {
      document.removeEventListener('scroll', onScroll2);
    }
  }, [])

  return (
    <div className="products-screen-comp" ref={productScreensRef}>
      <div ref={productBgRef} className="product-bg-0">
        <div className="h-screen flex items-center product-screen">
          <div ref={product0ContainRef} className="product-screen-contain">
            <div className="container">
              <div className="product-img-ph-cont">
                <img className="product-img-ph" ref={productImgRef} src={makromusicMobileImg} alt="mm" />
                <div className='product-img-ph-shadow'></div>
              </div>
              <div className="product-content" ref={productContentRef}>
                <img className="product-logo" src={makromusicLogo} alt="makromusic" />
                <p>{t("products0Desc")}</p>
                <a className='view-link-2' href={ROUTES.MAKROMUSIC} target="_blank" rel='noreferrer'>{t('productsView')} <img src={arrowIcon} alt='arrow' /> </a>
              </div>
              <div>
                <img className="product-img" ref={productImgRef} src={makromusicImg} alt="mm" />
              </div>
            </div>
          </div>
        </div>
        <div className="h-screen flex items-center product-screen">
          <div ref={product1ContainRef} className="product-screen-contain">
            <div className="container">
              <div className="product-img-ph-cont">
                <img className="product-img-ph" ref={productImgRef} src={makromusicMobileImg} alt="mm" />
                <div className='product-img-ph-shadow'></div>
              </div>
              <div className="product-content">
                <img className="product-logo" src={forArtistsLogo} alt="makromusic" />
                <p>{t("products1Desc")}</p>
                <a className='view-link-2' href={ROUTES.MAKROMUSIC_FOR_ARTISTS} target="_blank" rel='noreferrer'>{t('productsView')} <img src={arrowIcon} alt='arrow' /> </a>
              </div>
              <div>
                <img className="product-img" ref={productImgRef} src={forArtistsImg} alt="mm" />
              </div>
            </div>
          </div>
        </div>
        <div className="h-screen flex items-center product-screen">
          <div ref={product2ContainRef} className="product-screen-contain">
            <div className="container">
              <div className="product-img-ph-cont">
                <img className="product-img-ph" ref={productImgRef} src={makrosocialMobileImg} alt="mm" />
                <div className='product-img-ph-shadow'></div>
              </div>
              <div className="product-content" ref={productContentRef}>
                <img className="product-logo" src={makrosocialLogo} alt="makromusic" />
                <p>{t("products2Desc")}</p>
                <a className='view-link-2' href={ROUTES.MAKROSOCIAL} target="_blank" rel='noreferrer'>{t('productsView')} <img src={arrowIcon} alt='arrow' /> </a>
              </div>
              <div>
                <img className="product-img" ref={productImgRef} src={makrosocialImg} alt="makrosocial" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AnimateProducts
