import React from 'react'

const PressBox = ({img, title, desc, height, link}) => {
  return (
    <a href={link} target={'_blank'} rel='noreferrer' className="press-box" style={{height: height+'px'}}>
      <div className="press-box-poster" style={{backgroundImage: `url(${img})`}}>
      </div>
      <div className="press-box-content">
        <h3 className="title">{title}</h3>
        <p className="desc">{desc}</p>
      </div>
      <div className="press-box-shadow"></div>
    </a>
  )
}

export default PressBox
