import React from 'react'

const CommentBox = ({logo, extraLogo, comment, personName, personTitle}) => {
  return (
    <div className="comment-box">
      <img height={20} alt="makromusic logo" src={logo} />
      <p className="extra-logo">{extraLogo}</p>
      <div className="comment">
        {comment}
        <div className="person">
          <p className="person-name">{personName}</p>
          <p className="person-title">{personTitle}</p>
        </div>
      </div>

    </div>
  )
}

export default CommentBox
